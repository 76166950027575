function boom() {
  const blocks = document.querySelectorAll(".bowe-media.video");
  blocks.forEach(init);
  Object.defineProperty(HTMLMediaElement.prototype, "playing", {
    get: () => !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2),
  });
}

function init(elem) {
  let firstTimeClick = true;
  if (elem.classList.contains("video-js-init")) {
    return;
  }

  elem.classList.add("video-js-init");
  const video = elem.querySelector("video");
  const playButton = elem.querySelector(".media-video-play-btn");
  const playButtonSecondary = elem.querySelector(".media-video-play-btn-secondary");
  const poster = elem.querySelector(".thumbnail");
  //const overlay = elem.querySelector(".media-video-overlay");

  if (!video || !playButton) return;

  let playing = isPlaying(video);

  elem.onmouseleave = function () {
    if (!playing) {
      video.controls = false;
      //if (poster) poster.classList.remove("hidden");
    }
  };

  if (playButtonSecondary) {
    playButtonSecondary.addEventListener("click", () => {
      play();
      video.currentTime = playButtonSecondary.getAttribute("data-time");
    });
  }

  playButton.addEventListener("click", () => {
    play();
  });

  /*video.onplaying = function () {
    playing = true;
    sideEffect();
  };*/

  /*video.onpause = function () {
    playing = false;
    sideEffect();
  };*/

  video.addEventListener("pause", () => {
    if (!video.seeking) {
      // Check if the video is not currently seeking
      playing = false;
      pause();
    }
  });

  video.onended = function () {
    playing = false;
    sideEffect();
  };

  let timeout;

  function sideEffect() {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      (playing ? play : pause)();
    }, 50);
  }

  function play() {
    if (poster) poster.classList.add("hidden");
    elem.classList.add("media-is-playing");

    firstTimeClick ? (video.currentTime = 0) : "";
    video.controls = true;
    video.muted = false;
    firstTimeClick = false;
    playing = true;
    video.play();
  }

  function pause() {
    elem.classList.remove("media-is-playing");
  }

  function isPlaying(video) {
    return !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
  }
}

document.addEventListener("DOMContentLoaded", boom);
